import React, { lazy } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Navbar from "../Components/Navbar";
import Webfooter from "../Components/Footer";
import { AnimatePresence } from "framer-motion";
import { CartProvider } from "../ExcessCode/CartContext";
import { ProductProvider } from '../ExcessCode/ProductContext';
import { ProductIdProvider } from "../ExcessCode/ProductIdContext";

//pages imports

const Home = lazy(()=>import("../Pages/Home"));
const Login = lazy(()=>import("../Pages/Login"));
const Register = lazy(()=>import("../Pages/Register"));
const ShopPage = lazy(()=> import("../Pages/ShopPage"));
const Contact = lazy(()=>import("../Pages/Contact"));
const Gallery = lazy(() => import("../Pages/Gallery"));
const NewsEvent = lazy(()=> import("../Pages/NewsEvents"));
const NewsEventsData = lazy(()=> import("../Pages/NewsEventsData"));
const ProductPage = lazy(()=> import("../Pages/ProductPage"));
const ShoppingCart = lazy(()=>import("../Pages/ShoppingCart"));
const Search = lazy(() => import("../Components/Search"));
const Categoryres = lazy(()=> import("../Pages/Categoryres"));
const Profile = lazy(()=> import("../Pages/Profilepage"));
const Checkout = lazy(()=> import("../Pages/Checkout")); 
const OrderSuccess = lazy(()=> import("../Pages/OrderSuccess"));
const OrderFailed = lazy(()=> import("../Pages/OrderFailed") );
const MyOrders = lazy(()=> import("../Pages/MyOrders"));
const Feedback = lazy(()=> import("../Pages/Feedback"));
const Buisness = lazy(()=>import("../Pages/Buisness"));
const About = lazy(()=> import("../Pages/About"));
const Carrer = lazy(()=>import("../Pages/CareerPage"));
const ChangePassword = lazy(() => import("../Components/ChangePassword"));

// policy 
const Termscondition = lazy(()=> import("../Pages/Policy/Termsandcondition"));
const Privacypolicy = lazy(()=> import("../Pages/Policy/Privacypolicy"));
const Shippingpolicy = lazy(()=> import("../Pages/Policy/Shippingpolicy"));
const RefundCancellation = lazy(()=> import("../Pages/Policy/Refundandcancel"));

// others
const PrivateRoute = lazy(() =>
           import("../Pages/Private"));
const NotFound = lazy(()=> import("../Pages/404"));
//Admin imports
const AdminDash = lazy(() => import("../Pages/Admin/AdminDash"));
const ManageProducts = lazy(() => import("../Pages/Admin/Manageproduct"));
const AddProduct = lazy(() => import("../Pages/Admin/Addproduct"));
const EditProductForm = lazy(() => import("../Pages/Admin/UpdateProduct"));
const ManageExplore = lazy (()=>import("../Pages/Admin/ManageExplore"));
const AddExplore = lazy(()=> import('../Pages/Admin/AddExplore'));
const UpdateExplore = lazy(()=>import('../Pages/Admin/UpdateExplore'));
const ManageGallery = lazy (()=> import('../Pages/Admin/ManageGallery'));
const AddGallery = lazy(()=> import('../Pages/Admin/AddGallery'));
const ContactData = lazy(()=> import('../Pages/Admin/ContactData'));
const NewOrder = lazy(()=>import('../Pages/Admin/NewOrders'));
const DispatchOrders = lazy(()=> import('../Pages/Admin/DispatchOrders'));
const AllOrders = lazy(()=> import('../Pages/Admin/AllOrders'));
const CancelOrders = lazy(()=>import('../Pages/Admin/CancelOrders'));
const UserData = lazy(()=> import('../Pages/Admin/UserData'));
const FeedbackData = lazy(()=>import('../Pages/Admin/FeedbackData'));
const BuisnessEnquiry = lazy(()=> import('../Pages/Admin/BuisnessEnquiry'));

 function WebRoutes(){
    const location = useLocation();
    const isUserAdminRoute = location.pathname.startsWith("/admin");
    const isSitemap =location.pathname.startsWith("/sitemap");
    return (
      <AnimatePresence>
         <CartProvider>
         {!isUserAdminRoute  && !isSitemap   && <Navbar /> }
         <ProductIdProvider>
        <ProductProvider>
         <Routes location={location}>
          {/* Pages */}
         <Route path="/" element={<Home />} />
         <Route path="/login" element={<Login />} />
         <Route path="/register" element={<Register />} />
         <Route path="/contact-us" element={<Contact/>} />
         <Route path="/gallery" element={<Gallery />} />
         <Route path="/news-and-events" element={<NewsEvent/>} />
          <Route path="/news-and-events/:news_id" element={<NewsEventsData />} />
          <Route path="/shop" element={<ShopPage />} />
          <Route path="/shop/:category/:productName/:productId" element={<ProductPage />} />
          <Route path="/cart" element={<ShoppingCart />} />
          <Route path="/search/:text"  element={<Search />} />
          <Route path="/category/:category" element={<Categoryres />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/order-success" element={<OrderSuccess/>} />
          <Route path="/order-failed" element={<OrderFailed />} />
          <Route path="/feedback" element={<Feedback />} />
          <Route path="/buisness" element={<Buisness />} />
          <Route path="/about" element={<About />} />
          <Route path="/career" element={<Carrer />} />
          <Route path="/forgotpassword/:email" element={<ChangePassword />} />

          
        
          {/* policy */}
          <Route path="/terms-and-condition" element={<Termscondition/>} />
          <Route path="/privacy-policy" element={<Privacypolicy/>} />
          <Route path="/shipping-policy" element={<Shippingpolicy/>} />
          <Route path="/refund-and-cancellation-policy" element={<RefundCancellation />} />
   
        
           {/* Admin Routes */}
           <Route path="/admin" element={ <PrivateRoute type="admin"/> }>
          <Route path="/admin/dashboard" element={ <AdminDash /> } />
          <Route path="/admin/manageproducts" element={ <ManageProducts /> } />
          <Route path="/admin/manageproducts/addproduct" element={<AddProduct/>} />
          <Route path="/admin/manageproduct/edit/:id" element={ <EditProductForm />} />
          <Route path="/admin/manage-news-and-events" element={<ManageExplore/>} />
          <Route path="/admin/manage-news-and-events/add-newdata" element={<AddExplore/>} />
          <Route path="/admin/manage-news-and-events/update/:newsId" element={ <UpdateExplore/>} />
          <Route path="/admin/managegallery" element={<ManageGallery/>} />
          <Route path="/admin/managegallery/addgallery" element={<AddGallery />} />
          <Route path="/admin/contactenquiry" element={<ContactData />} />
          <Route path="/admin/neworder" element={<NewOrder />} />
          <Route path="/admin/dispatchorders" element={<DispatchOrders />} />
          <Route path="/admin/cancelorders" element={<CancelOrders />} />
          <Route path="/admin/allorders" element={<AllOrders />} />
          <Route path="/admin/userdata" element={<UserData />} />
          <Route path="/admin/feedbackdata" element={<FeedbackData/>} />
          <Route path="/admin/buisness-enquiry" element={<BuisnessEnquiry/>} />
          </Route> 
          {/* User */}
          <Route path="/user" element={<PrivateRoute type="user" />}>
          <Route path="/user/profile" element={<Profile />} />
          <Route path="/user/my-orders" element={<MyOrders />} />

</Route>
        
           {/* 404 Page */}
         <Route path="*" element={<NotFound />} />
         </Routes>
         </ProductProvider>
         </ProductIdProvider>
         {!isUserAdminRoute && !isSitemap  && <Webfooter/>}
         </CartProvider>
        </AnimatePresence>
    )
}
export default WebRoutes;

