import React from 'react';
import { Link } from 'react-router-dom';
import { IoHeart } from "react-icons/io5";
function Webfooter() {
  return (
   <>


<footer class="bg-white border">
    <div class="mx-auto w-full max-w-screen-xl">
      <div class="grid grid-cols-2 gap-5 px-4 py-6 lg:py-8 md:grid-cols-4">
        <div>
            {/* <h2 class="mb-4 text-sm font-semibold text-gray-900 uppercase dark:text-white">Company</h2> */}
            <ul class="text-gray-500 dark:text-gray-400 font-medium">
                <li class="mb-4">
                    <Link exact to="/buisness" class=" hover:underline">Buisness</Link>
                </li>
                <li class="mb-4">
                    <Link exact to="/career" class="hover:underline">Careers</Link>
                </li>
                <li class="mb-4">
                    <Link exact to="/feedback" class="hover:underline">Feedback</Link>
                </li>
                <li class="mb-4">
                    <Link exact to="/contact-us" class="hover:underline">Contact Us</Link>
                </li>
            </ul>
        </div>
        <div>
            {/* <h2 class="mb-4 text-sm font-semibold text-gray-900 uppercase dark:text-white">Help center</h2> */}
            <ul class="text-gray-500 dark:text-gray-400 font-medium">
                <li class="mb-4">
                    <Link exact to="/terms-and-condition" class="hover:underline">Tearms & Condition</Link>
                </li>
                <li class="mb-4">
                    <Link exact to="/privacy-policy" class="hover:underline">Privacy Policy</Link>
                </li>
                <li class="mb-4">
                    <Link exact to="/shipping-policy" class="hover:underline">Shipping Policy</Link>
                </li>
                <li class="mb-4">
                    <Link exact to="/refund-and-cancellation-policy" class="hover:underline">Refund & Cancellation</Link>
                </li>
            </ul>
        </div>
        <div>
            {/* <h2 class="mb-4 text-sm font-semibold text-gray-900 uppercase dark:text-white">Legal</h2> */}
            <ul class="text-gray-500 dark:text-gray-400 font-medium">
                <li class="mb-4">
                    <Link exact   to={`https://www.facebook.com/agrifert.in`} target="_blank" class="hover:underline">Facebook</Link>
                </li>
                <li class="mb-4">
                    <Link exact to={` https://www.instagram.com/agrifert.in/`} target="_blank" class="hover:underline">Instagram</Link>
                </li>
                <li class="mb-4">
                    <Link exact to={` https://x.com/agrifert_in`} target="_blank" class="hover:underline">Twitter</Link>
                </li>
                <li class="mb-4">
                    <Link exact  to={`https://in.linkedin.com/in/agrifert-india-410b211b0`} target="_blank" class="hover:underline">Linkedin</Link>
                </li>
            </ul>
        </div>
        <div>
            {/* <h2 class="mb-4 text-sm font-semibold text-gray-900 uppercase dark:text-white">Download</h2> */}
            <ul class="text-gray-500 dark:text-gray-400 font-medium">
               <li className='font-bold '>
               Register Office
               </li>
               <li>
               Serial No. 234/16/3/31 Office No.3, Pokar Arcade Near Domino’s
Pizza, Mhasrul, Nashik - 422004

               </li>
               <li>info@agrifert.in</li>
               <li>
              <Link exact to="tel:+919545011928 ">+919545011928 </Link>

               </li>
            </ul>
        </div>
    </div>
   
    </div>
    <div className="px-3 py-2 bg-[#1d1d1f] flex flex-wrap justify-between items-center">
      <span className="text-sm text-gray-200 w-full sm:w-auto text-center sm:text-left">
        © 2024 <a href="https://agrifert.in/" className="text-gray-200 underline">Agrifert India Pvt.Ltd</a>. All Rights Reserved.
      </span>
      <span className="text-sm text-gray-200 flex items-center justify-center w-full sm:w-auto hidden lg:flex">
        Made With <IoHeart className="text-red-500 mx-1 animate-pulse" /> In India
      </span>
      <span className="text-sm text-gray-200 w-full sm:w-auto text-center sm:text-right">
        Developed by ShivKalp IT Services
      </span>
    </div>
</footer>

   </>
  )
}

export default Webfooter;