import React from "react";
import { IoLogoWhatsapp, IoCallSharp } from "react-icons/io5";

const FloatingContact = () => {
  return (
    <>
      {/* WhatsApp Button */}
      <div className="fixed bottom-28 right-0 w-40 overflow-hidden bg-green-500 text-white rounded-l-xl z-10 transform translate-x-28 hover:translate-x-0 transition-transform duration-500 ">
        <a
          href="https://wa.me/+919975079793"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center justify-center text-white no-underline py-1 hover:bg-green-600"
        >
          <IoLogoWhatsapp className="text-3xl mr-2 transition-transform duration-500 " />
          <span>
            WhatsApp
            <br />
            <small>+919975079793</small>
          </span>
        </a>
      </div>

      {/* Call Button */}
      <div className="fixed bottom-10 right-0 w-40 overflow-hidden bg-blue-500 text-white rounded-l-xl z-10 transform translate-x-28 hover:translate-x-0 transition-transform duration-500">
        <a
          href="tel:+919975079793"
          className="flex items-center justify-center text-white no-underline py-1 hover:bg-blue-600"
        >
          <IoCallSharp className="text-3xl mr-2 transition-transform duration-500 " />
          <span>
            Call
            <br />
            <small>+919975079793</small>
          </span>
        </a>
      </div>
    </>
  );
};

export default FloatingContact;
